import "core-js/stable";
import "regenerator-runtime/runtime";
import $ from "jquery";
window.$ = $;
window.jQuery = $;

require("@rails/ujs").start();
require("turbolinks").start();
require("stylesheets/marketing.scss");

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import "controllers";
